<template>
    <div class="fill-height">

        <div v-if="showLoginScreen" class="d-flex align-center justify-center h-100">
            <fake-login-form
                key-login="2024"
                key-password="2024"
                style="max-width: 500px;"
                autologin-by-query
                @success="loadResource"
            />
        </div>

        <template v-else>
            <!-- Loading icon -->
            <div v-if="loading" class="fill-height d-flex flex-row justify-center align-center">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="#1976d2"
                    indeterminate
                />
            </div>
            <!-- END Loading icon -->

            <!-- Error -->
            <div v-if="!_.isNil(error)" class="fill-height d-flex flex-row justify-center align-center">
                <v-alert type="error">
                    {{ error }}
                </v-alert>
            </div>
            <!-- END Error -->

            <!-- View -->
            <iframe 
                v-if="hasUrl"
                :src="customUrl" 
                scrolling="no" 
                frameborder="no"
                class="vw-100 vh-100"
            ></iframe>
            <!-- END View -->
        </template>
    </div>
</template>
<script>
import FakeLoginForm from '@/components/auth/FakeLoginForm.vue';

export default {
    name: 'ResourceView',
    components: { FakeLoginForm },
    data () {
        return {
            showLoginScreen: false,
            loading: true,
            error: null,
            data: null
        }
    },
    computed: {
        shortId() {
            return _.get(this.$route, 'params.shortId', '');
        },
        hasUrl() {
            return _.size(_.get(this.data, 'url', '')) > 0;
        },
		customUrl() {
            if(!this.hasUrl)
                return null;
			return _.join([this.data.url, `rootCustomView=1&rootBackgroundUrl=${btoa(_.get(this, 'data.data.preview.rootBackgroundUrl', ''))}`], this.data.courseTypeId > 0 ? '&' : '?');
		},
        isViewForMinistry () {
            return this.$route?.path?.startsWith?.('/r/');
        }
    },
    async created() {
        if (this.isViewForMinistry)
            this.showLoginScreen = true;
        else
            this.loadResource();
    },
    methods: {
        async loadResource () {
            this.showLoginScreen = false;
            const { data, error } = await this.$store.dispatch('app/resource', {
                shortId: this.shortId
            });

            this.data = data;
            this.error = error;

            this.loading = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>